import React from 'react'
import { IoCall } from 'react-icons/io5'
import { Link } from 'react-router-dom'
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn } from 'react-icons/fa';
import { FaYoutube } from 'react-icons/fa6';

const Footer = () => {

    const contactUsLink = [
        { title: "VISIT US", link: "https://maps.app.goo.gl/tAbh31BGbuoGDyP48" },
        { title: "ABOUT US", link: "/about-us" },
        { title: "CONTACT US", link: "/contactus/" },
    ]
    return (
        <>

            {/* <div className='flex justify-center '>
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3847.1779855765935!2d75.11881277478287!3d15.366844085215424!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bb8d7376cee471f%3A0xd1628aae8d84451a!2sWedding%20Cluster!5e0!3m2!1sen!2sin!4v1697187521924!5m2!1sen!2sin"
                    className=" w-full  rounded-lg md:w-[700px] border-0"
                    style={{ filter: "opacity(0.8)" }}
                    allowFullScreen
                    loading="lazy"
                ></iframe>
            </div> */}
            <div className=" right-0 w-full h-auto bg-[#ECECEC] z-10 flex flex-col md:flex-row justify-evenly items-center  px-4 sm:px-10 lg:px-44 rounded-r-3xl">
                <div className="right-0 w-full h-auto bg-[#ECECEC] z-10 flex flex-col md:flex-row  justify-evenly items-start  md:py-16  rounded-r-3xl">

                    {/* Left Section - Buttons */}
                    <div className="w-full py-5 sm:w-6/12 md:w-3/12 flex flex-col items-center gap-2">
                        {contactUsLink.map((data, index) => (
                            <Link
                                to={`${data.link}`}
                                onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
                                className="bg-primarycolor text-white shadow-lg sm:shadow-xl md:shadow-2xl lg:shadow-[0px_10px_30px_rgba(0,0,0,0.3)] p-2 rounded-md w-32 text-center transition-all duration-300 hover:shadow-[0px_15px_40px_rgba(0,0,0,0.4)]"
                                key={index}
                            >
                                {data.title}
                            </Link>
                        ))}

                        <p className='md:hidden font-bold py-3 border-b-2 border-yellow-700 flex justify-center items-center text-primarycolor text-md'><IoCall className='mr-2' /> Tel <span className='font-normal'>+91 8787676768</span> </p>
                    </div>

                    {/* Center Section - Address */}
                    <div className=" hidden w-full sm:w-8/12 md:w-4/12 md:flex flex-col items-center gap-1 border-r-0 md:border-r-2 border-l-0 md:border-l-2 border-yellow-700 text-center md:text-left">
                        <p className="text-primarycolor font-bold text-xl px-3 text-center">Sanganabasava International Residential School</p>
                        <p className="text-md text-primarycolor">Residential School, Kavalgi Post</p>
                        <p className="text-md text-primarycolor">Honnutagi, Vijayapura,</p>
                        <p className="text-md text-primarycolor"> Karnataka, India - 586101</p>
                        <p className="text-md text-primarycolor font-semibold">
                            <a href="mailto:contact@sirs.ac.in ">contact@sirs.ac.in </a>
                        </p>
                        <p className="text-md text-primarycolor font-semibold">+91 73386 28376</p>
                    </div>

                    {/* Right Section - Key Links */}
                    <div className="hidden w-full sm:w-6/12 md:w-3/12 md:flex flex-col items-center gap-2 mt-4 md:mt-0">
                        <p className="text-primarycolor font-extrabold text-lg">Key Links</p>
                        <Link to={'/'} className="text-primarycolor">OUR SCHOOL</Link>
                        <Link to={'https://maps.app.goo.gl/tAbh31BGbuoGDyP48'} className="text-primarycolor">VISIT US</Link>
                        <Link to={'/about-us'} className="text-primarycolor">ABOUT US</Link>
                        <Link to={'/mandatory-disclosers'} className="text-primarycolor">MANDATORY DISCLOSERS</Link>
                        {/* <Link to={'/academics-and-infra/results'} className="text-primarycolor">ACADEMICS</Link> */}
                        {/* <Link className="text-primarycolor">CONTACT US</Link> */}
                    </div>

                    <div className=" hidden md:block w-[200px] justify-center border-l-0 md:border-l-2 border-yellow-700 px-3 ps-5">
                        <p className="text-primarycolor font-extrabold text-lg mb-3">Social Media</p>
                        <img
                            src="/Instagram.jpg"
                            className="w-full max-w-[120px] h-auto aspect-square object-cover"
                            alt="instagram scanner"
                        />
                    </div>
                </div>

            </div>
            <div>

                <div className=" flex flex-col gap-2 justify-center items-center  bg-primarycolor p-4 ">
                    <h1 className="text-white text-xl sm:text-xl md:text-xl lg:text-xl xl:text-xl text-center">
                        Connect with SIRS
                    </h1>
                    <div className='flex gap-3'>
                        <a
                            href="https://www.facebook.com/profile.php?id=100092030040139"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="bg-blue-600 p-2 rounded-full inline-flex items-center justify-center hover:bg-yellow-500 transition-colors duration-300"
                        >
                            <FaFacebookF className="text-white text-xl" />
                        </a>
                        <a
                            href="https://www.instagram.com"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="bg-gradient-to-r from-pink-500 to-yellow-500 p-2 rounded-full inline-flex items-center justify-center hover:opacity-80 transition-opacity duration-300"
                        >
                            <FaInstagram className="text-white text-xl" />
                        </a>
                        <a
                            href="https://www.youtube.com/@sanganabasavainternational3360"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="bg-red-600 p-2 rounded-full inline-flex items-center justify-center hover:bg-yellow-500 transition-colors duration-300"
                        >
                            <FaYoutube className="text-white text-xl" />
                        </a>

                    </div>
                    <h1 className='text-white'>Copyright © 2025.</h1>
                </div>
            </div>


        </>
    )
}

export default Footer