import React from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Link, useNavigate } from 'react-router-dom'
import axios from "axios"
import { useDispatch } from 'react-redux'
import { addUser } from '../utils/redux/slices/userSlice'
import { BASEURL } from '../utils/Constant';

const Login = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const formik = useFormik({
        initialValues: {
            emailId: '',
            password: '',
        },
        validationSchema: Yup.object({
            emailId: Yup.string().email('Invalid email address').required('Required'),
            password: Yup.string().required('Required'),
        }),

        onSubmit: (values) => {
            // console.log(values);
            handleLogin(values)
        },
    })

    const handleLogin = async (values) => {
        // console.log("handleLogin function called");

        try {
            const res = await axios.post(BASEURL + "login", values, {
                withCredentials: true
            });
            const userData = res?.data?.data
            // console.log("Login successful", userData);
            // console.log("token", res?.data?.token);
            dispatch(addUser(userData))
            localStorage.setItem("token", res?.data?.token)
            navigate("/admin-dashboard")

        } catch (error) {
            if (error.response) {
                console.error("Error response:", error.response.data);
                alert("Login failed: " + error.response.data.message);
            } else if (error.request) {
                console.error("Error request:", error.request);
                alert("Login failed: No response from server.");
            } else {
                console.error("Error while setting up request:", error.message);
                alert("Login failed: " + error.message);
            }
        }
    };


    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
            <div className="bg-white p-8 rounded shadow-md w-full max-w-md">
                <h2 className="text-2xl text-primarycolor font-bold mb-6 text-center">Login</h2>
                <form onSubmit={formik.handleSubmit}>
                    <div className="mb-4">
                        <label className="block text-primarycolor" htmlFor="emailId">Email</label>
                        <input
                            id="emailId"
                            name="emailId"
                            type="email"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                            className={`mt-1 block w-full p-2 border ${formik.touched.emailId && formik.errors.emailId ? 'border-red-500' : 'border-gray-300'
                                } rounded`}
                        />
                        {formik.touched.emailId && formik.errors.emailId ? (
                            <div className="text-red-500 text-sm mt-1">{formik.errors.emailId}</div>
                        ) : null}
                    </div>

                    <div className="mb-4">
                        <label className="block text-primarycolor" htmlFor="password">Password</label>
                        <input
                            id="password"
                            name="password"
                            type="password"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.password}
                            className={`mt-1 block w-full p-2 border ${formik.touched.password && formik.errors.password ? 'border-red-500' : 'border-gray-300'
                                } rounded`}
                        />
                        {formik.touched.password && formik.errors.password ? (
                            <div className="text-red-500 text-sm mt-1">{formik.errors.password}</div>
                        ) : null}
                    </div>

                    <button type="submit" className="bg-blue-500 text-white w-full p-2 rounded">Login</button>
                </form>
                <p className='text-primarycolor'>Dont have an account, <Link
                    to={'/sirsregister'} className='text-blue-600'>Register here</Link></p>
            </div>
        </div>
    )
}

export default Login