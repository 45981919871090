import { createSlice } from "@reduxjs/toolkit";

const contactSlice = createSlice({
    name: "contact",
    initialState: {
        isContactOpen: false
    },
    reducers: {
        toggleContact: (state) => {
            state.isContactOpen = !state.isContactOpen
        },
        closeContact: (state) => {
            state.isContactOpen = false
        }
    }
})

export const { toggleContact, closeContact } = contactSlice.actions
export default contactSlice.reducer;