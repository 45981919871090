import { Star } from 'lucide-react';

export const BASEURL = process.env.REACT_APP_BASE_URL
// export const BASEURL = "http://localhost:1234/api/"


export const latestStoriesData = [
    {
        "name": "Teachers Training",
        "image": [
            "https://ik.imagekit.io/3fmualis1/DSC_0153.JPG?updatedAt=1739881960067",
            "https://ik.imagekit.io/3fmualis1/DSC_0200.JPG?updatedAt=1739881960077",
            "https://ik.imagekit.io/3fmualis1/DSC_0188.JPG?updatedAt=1739881959689",
            "https://ik.imagekit.io/3fmualis1/DSC_0077.JPG?updatedAt=1739881958954",
            "https://ik.imagekit.io/3fmualis1/DSC_0049.JPG?updatedAt=1739881925916"
        ],
        "number": 1
    },
    {
        "name": "Annual Day",
        "image": [
            "/annualday.jpeg",
            "/annualday1.jpeg",
            "/annualday2.jpeg",
            "/annualday3.jpeg",
            "/annualday4.jpeg",
            "/annualday5.jpeg",
        ],
        "number": 2
    },
    {
        "name": "Allamatti Trip",
        "image": [
            "https://ik.imagekit.io/3fmualis1/DSC_0041.JPG?updatedAt=1739882904744",
            "https://ik.imagekit.io/3fmualis1/DSC_0065.JPG?updatedAt=1739882904657",
            "https://ik.imagekit.io/3fmualis1/DSC_0008.JPG?updatedAt=1739882904265",
            "https://ik.imagekit.io/3fmualis1/DSC_0003.JPG?updatedAt=1739882904211",
            "https://ik.imagekit.io/3fmualis1/DSC_0209.JPG?updatedAt=1739882883862",
        ],
        "number": 3
    },
    {
        "name": "Art And Craft",
        "image": [
            "https://ik.imagekit.io/3fmualis1/DSC_0102.JPG?updatedAt=1739883318240",
            "https://ik.imagekit.io/3fmualis1/DSC_0175.JPG?updatedAt=1739883285002",
            "https://ik.imagekit.io/3fmualis1/DSC_0291.JPG?updatedAt=1739883166585",
            "https://ik.imagekit.io/3fmualis1/DSC_0288.JPG?updatedAt=1739883166334",
            "https://ik.imagekit.io/3fmualis1/DSC_0306.JPG?updatedAt=1739883147767",
        ],
        "number": 4
    },
    {
        "name": "Drown Work Shop",
        "image": [
            "https://ik.imagekit.io/3fmualis1/DSC_0241.JPG?updatedAt=1739883515058",
            "https://ik.imagekit.io/3fmualis1/DSC_0217.JPG?updatedAt=1739883478597",
            "https://ik.imagekit.io/3fmualis1/DSC_0217.JPG?updatedAt=1739883478597",
        ],
        "number": 5
    },
    {
        "name": "Inter House Competation",
        "description": "Exciting Inter-House Competition Showcasing Talent and Team Spirit.",
        "image": [
            "https://ik.imagekit.io/3fmualis1/DSC_0070%20-%20Copy.JPG?updatedAt=1739883318964",
            "https://ik.imagekit.io/3fmualis1/DSC_0027%20-%20Copy.JPG?updatedAt=1739883319138",
            "https://ik.imagekit.io/3fmualis1/DSC_0032.JPG?updatedAt=1739883319127",
            "https://ik.imagekit.io/3fmualis1/DSC_0070%20-%20Copy.JPG?updatedAt=1739883318964",
            "https://ik.imagekit.io/3fmualis1/DSC_0155.JPG?updatedAt=1739883318719",
        ],
        "number": 6
    }
]

export const menuItems = [
    { label: "About Us", link: "/about-us", icon: Star },
    { label: "Vision and Mission", link: "/about-us/vision-mission", icon: Star },
    { label: "President's Message", link: "/about-us/president-message", icon: Star },
    { label: "Chairman's Nominee Message", link: "/about-us/chairman-message", icon: Star },
    { label: "CAO's Message", link: "/about-us/cao-message", icon: Star },
    { label: "Principal's Message", link: "/about-us/principal-message", icon: Star },
    { label: "Board of Director's", link: "/about-us/board-of-directors", icon: Star },
]

export const primaryMenuItems = [
    { label: "Primary School", link: "/primary-school", icon: Star },
    { label: "Senior School", link: "/senior-school", icon: Star },
    { label: "About Us", link: "/about-us", icon: Star },
]
export const seniorMenuItems = [
    { label: "Senior School", link: "/senior-school", icon: Star },
    { label: "Primary School", link: "/primary-school", icon: Star },
    { label: "About Us", link: "/about-us", icon: Star },
]

export const afterSchoolMenuItems = [
    { label: "After School Activities", link: "/after-school-activities", icon: Star },
    { label: "Creative Arts", link: "/creative-arts", icon: Star },
    { label: "Sports at SIRS", link: "/sports", icon: Star },
]

export const boardingMenuItems = [
    { label: "Boarding at SIRS", link: "/boarding", icon: Star },
    { label: "Boarding Activities", link: "/boarding-activities", icon: Star },
]

export const staffData = [
    { id: 1, name: "Mrs. Sharmila Hemant", qualification: "M.A., B.Ed.", designation: "Principal" },
    { id: 2, name: "Mr. Annappa Shiroor", qualification: "M.A., B.Ed.", designation: "Vice Principal & PGT in Kannada" },
    { id: 3, name: "Mr. Narendra Ramachandra Bhat", qualification: "Vidwan., B.Ed.", designation: "PGT in Sanskrit" },
    { id: 4, name: "Mr. Sharanagouda Desai", qualification: "M.Sc., B.Ed.", designation: "Maths Teacher" },
    { id: 5, name: "Mrs. Geeta S Sajjan", qualification: "M.Sc. Comp. Sci.", designation: "Computer Teacher" },
    { id: 6, name: "Mrs. Renuka Metagudda", qualification: "D.M.C.", designation: "Craft Teacher" },
    { id: 7, name: "Mr. Gurupadappa M. Masali", qualification: "Folk & Western", designation: "Dance Teacher" },
    { id: 8, name: "Mrs. Danamma Andanimath", qualification: "M.A., B.Ed.", designation: "Kannada Teacher" },
    { id: 9, name: "Mr. Sangamesh S. Bhustali", qualification: "M.A., B.Ed.", designation: "TGT in Hindi" },
    { id: 10, name: "Mrs. Shivaganga Vastrad", qualification: "B.Sc., B.Ed.", designation: "TGT in Maths" },
    { id: 30, name: "Mrs. Revati S Shinde", qualification: "M.A, B. Ed.", designation: "TGT English" },
    { id: 31, name: "Ms. Vidyashree Ganganahalli", qualification: "M.Sc., B. Ed.", designation: "TGT Science" },
    { id: 32, name: "Ms. Sudha R.", qualification: "B.A, B. Ed. (M.A)", designation: "Tutor" },
    { id: 33, name: "Mr. Kareppa Potennavar", qualification: "B. P. Ed., M. P. Ed.", designation: "Physical Edu. Teacher" },
    { id: 34, name: "Mr. Anilkumar Mahendrakar", qualification: "B.Sc.., B.Ed.", designation: "TGT Science" },
    { id: 35, name: "Mrs. Mruganshika More", qualification: "B.Com", designation: "TGT in Social Science & English" },
    { id: 36, name: "Mr. Pandurang Kulkarni", qualification: "M.A, B. Ed.", designation: "TGT Social Science" },
    { id: 37, name: "Mr. Gaddeppagouda Patil", qualification: "B.A, B. Ed.", designation: "TGT Kannada" },
    { id: 38, name: "Mrs. Vaishali Shilin", qualification: "M. Sc., B. Ed.", designation: "TGT Mathematics" },
    { id: 39, name: "Mrs. Veerabasavva M Gurammanavara", qualification: "M.A, B. Ed.", designation: "TGT English" },
    { id: 40, name: "Mr. Sharanappa Kuri", qualification: "M. Sc., B. Ed.", designation: "TGT Maths" },
    { id: 41, name: "Mrs. Shridevi P Konnur Patil", qualification: "M.A, B. Ed.", designation: "TGT Hindi" },
    { id: 42, name: "Mr. Kiran Talewad", qualification: "B.Com.", designation: "Karate Master" },
    { id: 43, name: "Mrs. Felina F. Carvalho", qualification: "M.A, B. Ed.", designation: "TGT English" },
    { id: 44, name: "Ms. Vidhya Saji", qualification: "M. A.", designation: "TGT SST" },
    { id: 45, name: "Ms. Soundarya Hiremath", qualification: "B. Sc., B. Ed.", designation: "TGT Science" },
    { id: 46, name: "Ms. Aishwarya H M", qualification: "M. Sc., B. Ed.", designation: "TGT Mathematics" },
    { id: 47, name: "Ms. Sushma B", qualification: "M. Sc. (Mathematics)", designation: "Tutor" },
    { id: 48, name: "Mr. Pruthviraj S Pawar", qualification: "PUC, (B.Sc.)", designation: "Dance Teacher" },
    { id: 49, name: "Ms. Sharanamma", qualification: "Master of Music in Hindustani Vocal", designation: "Music Teacher" },
    { id: 50, name: "Mrs. Mini Joseph", qualification: "M.A, B. Ed.", designation: "TGT SST" },
    { id: 51, name: "Mr. Veeranna B Babli", qualification: "B.A., B.Ed. M. Li. Sc.", designation: "Librarian" },
    { id: 52, name: "Mr. Sangamesh Bilagi", qualification: "PET", designation: "PET" }
]

export const juniorWings = [
    { name: "Mrs. Jyothi Padasali", qualification: "M.Sc., B.Ed.", role: "Head Junior Wing" },
    { name: "Mrs. Iramma Tegginakeri", qualification: "B.A., B.Ed.", role: "PRT" },
    { name: "Mrs. Anuradha Pattar", qualification: "B.E. ENTC", role: "PRT (Mother Teacher)" },
    { name: "Mrs. Channamma Hiremath", qualification: "B.A., B.Ed., NTTC", role: "Primary Teacher" },
    { name: "Mrs. Asha A. Shiroor", qualification: "B.A.", role: "PRT (Kannada)" },
    { name: "Mrs. Priyanka S Patil", qualification: "M. Sc.", role: "PRT Science" },
    { name: "Mrs. Geeta Ramdurg", qualification: "M.A., B.Ed.", role: "PRT" },
    { name: "Ms. Supriya R. Kumbar", qualification: "B.Com (M.Com)", role: "PRT Mathematics" },
    { name: "Ms. Shreya S Rokhade", qualification: "B.C.A.", role: "PRT Computer Teacher" },
    { name: "Mrs. Sangeeta Mahendrakar", qualification: "B.A., B.Ed. (D.Ed. (Social & Maths), Hindi (Rajya Bhasha Prakash))", role: "PRT in Hindi" },
    { name: "Mrs. Reshma Rao", qualification: "PUC, D. Ed.", role: "Pre Primary Teacher" },
    { name: "Ms. Shweta Kalagurki", qualification: "M. Sc.", role: "PRT Science" },
    { name: "Ms. Madhuri A. Bagave", qualification: "B.A., M.Sc. Geography", role: "PRT - SST" },
    { name: "Mrs. Pushpa S P", qualification: "PUC, D.Ed., B.Ed.", role: "PRT English" },
    { name: "Ms. Vishalaxi S Ganganahalli", qualification: "B.Com", role: "Tutor" }
];

export const ADMINISTRATIVE_STAFF = [
    { name: "Mr. Shivamoorthy S Ukumanal", qualification: "DTM", role: "Office Superintendent" },
    { name: "Mr. Ramesh B. Managuli", qualification: "B.A.", role: "Campus Manager" },
    { name: "Mr. Shrikant Axanti,", qualification: "B.Com", role: "Asst. Accountant" },
    { name: "Mr. Naganna Amadal", qualification: "B.A., D.Ed., M.A, B.Ed.", role: "Store Asst." },
    { name: "Mr. Rachanna N. Golai", qualification: "B.Sc., B.Ed.", role: "Office Asst." },
    { name: "Mr. Shrishail S Pujari", qualification: "B.E. in CS", role: "F.D.A." },
    { name: "Ms. Jyothi Natikar", qualification: "PUC", role: "Hostel Warden" },
    { name: "Mr. Krishna Poddar", qualification: "B.Com", role: "Asst. Accountant / Computer" },
    { name: "Ms. Ashwini Kamble", qualification: "BAMS", role: "Doctor" },
    { name: "Ms. Mahantamma R Bhavikatti", qualification: "B.A., D.Ed.", role: "Hostel Warden" },
    { name: "Mr. Shivagond Melappagol", qualification: "B.Com", role: "Hostel Warden" },
    { name: "Mr. Somaling S. Kumatagi", qualification: "B.A.", role: "Hostel Warden" },
    { name: "Mr. Anil Agasar", qualification: "Diploma Nursing", role: "Brother Nurse (Male)" },
    { name: "Mrs. Jyoti Potennavar", qualification: "B.A., Diploma in Health Inspector", role: "Staff Nurse" },
    { name: "Ms. Pavitra Keshapur", qualification: "B.Com (M.Com)", role: "Hostel Warden" },
    { name: "Mr. Simon Peter P.J.", qualification: "Diploma Electronics and Electrical", role: "System Manager / Hostel Supervisor" },
    { name: "Mr. Sangamesh N Sankanagoudar", qualification: "B.A.", role: "Hostel Warden" },
    { name: "Mr. Mahadev Hubballi", qualification: "N/A", role: "Hostel Supervisor" },
    { name: "Mr. Nagaraj Bhajantri", qualification: "N/A", role: "Tutor" },
    { name: "Mr. Tammanna Halagabal", qualification: "B.A.", role: "Warden" }
];