import { useState, useEffect } from 'react';
import axios from 'axios';
import { BASEURL } from '../utils/Constant';

const useGetPopUpImage = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [popUpImages, setPopUpImages] = useState([]);

    const getPopUpImage = async () => {
        setLoading(true);
        setError(null);

        try {
            const response = await axios.get(BASEURL + "get-pop-up");
            setPopUpImages(response.data.data);
            // console.log("popup image ", response.data.data);
        } catch (err) {
            setError(err.message);
            console.error("Error fetching pop-up images:", err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getPopUpImage();
    }, []);

    return { loading, error, popUpImages };
};

export default useGetPopUpImage;
