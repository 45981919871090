import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { BASEURL } from '../utils/Constant';
import { AiOutlineLoading3Quarters } from 'react-icons/ai'; // Import spinner

const ContactUs = () => {
    const [loading, setLoading] = useState(false);
    const [success, setsuccess] = useState("")

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            phonenumber: '',
            message: ''
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Name is required'),
            email: Yup.string().email('Invalid email address').required('Email is required'),
            phonenumber: Yup.string()
                .matches(/^[6-9]\d{9}$/, 'Enter a valid Indian phone number')
                .required('Phone number is required'),
            message: Yup.string().required('Message is required').min(10, 'Message must be at least 10 characters')
        }),
        onSubmit: async (values, { resetForm }) => {
            await handleContactUsForm(values, resetForm);
        }
    });

    const handleContactUsForm = async (values, resetForm) => {
        setLoading(true);
        try {
            const response = await axios.post(BASEURL + 'contactus', values);
            console.log('response', response);
            // alert('Email Sent Successfully!');
            setsuccess("Email Sent Successfully!")
            resetForm();
        } catch (error) {
            console.error('Error submitting form:', error);
            alert('An error occurred while sending your message.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="pt-36 pb-20 bg-gray-100">

            <div className="max-w-lg mx-auto p-6 bg-white shadow-md rounded-lg">
                {success && (
                    <div className='bg-green-400 p-2 text-center'>
                        <h1 className='text-white'>{success || "Email Sent successfully!"}</h1>
                    </div>
                )}
                <h2 className="text-2xl font-semibold mb-6 text-primarycolor">Contact Us</h2>
                <form onSubmit={formik.handleSubmit}>
                    {/* Name Field */}
                    <div className="mb-4">
                        <label className="block text-primarycolor" htmlFor="name">Name</label>
                        <input
                            id="name"
                            name="name"
                            type="text"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.name}
                            className={`mt-1 block w-full p-2 border ${formik.touched.name && formik.errors.name ? 'border-red-500' : 'border-gray-300'} rounded`}
                        />
                        {formik.touched.name && formik.errors.name && (
                            <div className="text-red-500 text-sm mt-1">{formik.errors.name}</div>
                        )}
                    </div>

                    {/* Email Field */}
                    <div className="mb-4">
                        <label className="block text-primarycolor" htmlFor="email">Email</label>
                        <input
                            id="email"
                            name="email"
                            type="email"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                            className={`mt-1 block w-full p-2 border ${formik.touched.email && formik.errors.email ? 'border-red-500' : 'border-gray-300'} rounded`}
                        />
                        {formik.touched.email && formik.errors.email && (
                            <div className="text-red-500 text-sm mt-1">{formik.errors.email}</div>
                        )}
                    </div>

                    {/* Phone Number Field */}
                    <div className="mb-4">
                        <label className="block text-primarycolor" htmlFor="phonenumber">Phone Number</label>
                        <input
                            id="phonenumber"
                            name="phonenumber"
                            type="tel"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.phonenumber}
                            className={`mt-1 block w-full p-2 border ${formik.touched.phonenumber && formik.errors.phonenumber ? 'border-red-500' : 'border-gray-300'} rounded`}
                        />
                        {formik.touched.phonenumber && formik.errors.phonenumber && (
                            <div className="text-red-500 text-sm mt-1">{formik.errors.phonenumber}</div>
                        )}
                    </div>

                    {/* Message Field */}
                    <div className="mb-4">
                        <label className="block text-primarycolor" htmlFor="message">Message</label>
                        <textarea
                            id="message"
                            name="message"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.message}
                            rows="4"
                            className={`mt-1 block w-full p-2 border ${formik.touched.message && formik.errors.message ? 'border-red-500' : 'border-gray-300'} rounded`}
                        ></textarea>
                        {formik.touched.message && formik.errors.message && (
                            <div className="text-red-500 text-sm mt-1">{formik.errors.message}</div>
                        )}
                    </div>

                    {/* Submit Button */}
                    <button
                        type="submit"
                        className={`bg-primarycolor text-white p-2 rounded hover:bg-opacity-90 w-full ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                        disabled={loading}
                    >
                        {loading ? (
                            <div className="flex justify-center items-center">
                                <AiOutlineLoading3Quarters className="animate-spin h-5 w-5 mr-2" />
                                Sending...
                            </div>
                        ) : (
                            'Send Message'
                        )}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default ContactUs;
