import { createSlice } from "@reduxjs/toolkit";

const menueSlice = createSlice({
    name: "menu",
    initialState: {
        isMenueOpen: false
    },
    reducers: {
        toggleMenu: (state) => {
            state.isMenueOpen = !state.isMenueOpen
        },
        closeMenue: (state) => {
            state.isMenueOpen = false
        }
    }
})

export const { toggleMenu, closeMenue } = menueSlice.actions
export default menueSlice.reducer;