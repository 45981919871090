import React, { useEffect, useState } from 'react'
import axios from "axios"
import { BASEURL } from '../utils/Constant'

const useGetAllSlider = () => {
    const [sliderImage, setSliderImage] = useState([])

    const fetchSlider = async () => {
        try {
            // console.log("BASEURL: ",BASEURL)
            const response = await axios.get(BASEURL + "get-all-slider")
            // console.log("response", response?.data?.data)
            setSliderImage(response?.data?.data) // Assuming the data is in response.data
        } catch (error) {
            console.log("error", error)
        }
    }

    useEffect(() => {
        fetchSlider()
    }, [])

    return { sliderImage }
}

export default useGetAllSlider
