import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { BASEURL } from '../utils/Constant';

const Register = () => {

    const navigate = useNavigate()

    const formik = useFormik({
        initialValues: {
            userName: '',
            emailId: '',
            password: '',
        },
        validationSchema: Yup.object({
            userName: Yup.string()
                .min(3, 'Must be at least 3 characters')
                .required('Required'),
            emailId: Yup.string()
                .email('Invalid email address')
                .required('Required'),
            password: Yup.string()
                .min(8, 'Must be at least 8 characters')
                .matches(
                    /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[@$!%*?&])/,
                    'Must contain uppercase, lowercase, number, and special character'
                )
                .required('Required'),
        }),
        onSubmit: (values) => {
            // Handle form submission
            // console.log('Form data', values);
            handleRegister(values)
        },
    });


    const handleRegister = async (values) => {
        // console.log("handleRegister function called");

        try {
            const res = await axios.post(BASEURL + "newuser", values);
            const userData = res?.data?.data
            // console.log("Register successful", userData);
            navigate("/login")

        } catch (error) {
            if (error.response) {
                console.error("Error response:", error.response.data);
                alert("Register failed: " + error.response.data.message);
            } else if (error.request) {
                console.error("Error request:", error.request);
                alert("Register failed: No response from server.");
            } else {
                console.error("Error while setting up request:", error.message);
                alert("Register failed: " + error.message);
            }
        }
    };


    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
            <div className="bg-white  p-8 rounded shadow-md w-full max-w-md">
                <h2 className="text-2xl text-primarycolor font-bold mb-6 text-center">Register</h2>
                <form onSubmit={formik.handleSubmit}>
                    {/* User Name Field */}
                    <div className="mb-4">
                        <label className="block text-primarycolor" htmlFor="userName">
                            User Name
                        </label>
                        <input
                            id="userName"
                            name="userName"
                            type="text"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.userName}
                            className={`mt-1 block w-full p-2 border ${formik.touched.userName && formik.errors.userName
                                ? 'border-red-500'
                                : 'border-gray-300'
                                } rounded`}
                        />
                        {formik.touched.userName && formik.errors.userName ? (
                            <div className="text-red-500 text-sm mt-1">
                                {formik.errors.userName}
                            </div>
                        ) : null}
                    </div>

                    {/* Email Field */}
                    <div className="mb-4">
                        <label className="block text-primarycolor" htmlFor="emailId">
                            Email Address
                        </label>
                        <input
                            id="emailId"
                            name="emailId"
                            type="email"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.emailId}
                            className={`mt-1 block w-full p-2 border ${formik.touched.emailId && formik.errors.emailId
                                ? 'border-red-500'
                                : 'border-gray-300'
                                } rounded`}
                        />
                        {formik.touched.emailId && formik.errors.emailId ? (
                            <div className="text-red-500 text-sm mt-1">
                                {formik.errors.emailId}
                            </div>
                        ) : null}
                    </div>

                    {/* Password Field */}
                    <div className="mb-6">
                        <label className="block text-primarycolor" htmlFor="password">
                            Password
                        </label>
                        <input
                            id="password"
                            name="password"
                            type="password"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.password}
                            className={`mt-1 block w-full p-2 border ${formik.touched.password && formik.errors.password
                                ? 'border-red-500'
                                : 'border-gray-300'
                                } rounded`}
                        />
                        {formik.touched.password && formik.errors.password ? (
                            <div className="text-red-500 text-sm mt-1">
                                {formik.errors.password}
                            </div>
                        ) : null}
                    </div>

                    {/* Submit Button */}
                    <button
                        type="submit"
                        className="bg-primarycolor hover:bg-primarycolorlight text-white w-full p-2 rounded"
                    >
                        Register
                    </button>
                    <p className='text-primarycolor'>Alredy have an account, <Link
                        to={'/login'} className='text-blue-600'>Login</Link></p>
                </form>
            </div>
        </div>
    )
}

export default Register