import { combineReducers, configureStore } from "@reduxjs/toolkit";
import menuSlice from "./slices/menuSlice"
import contactSlice from "./slices/contactSlice"
import userSlice from "./slices/userSlice"
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from "redux-persist"; // Import 

const persistConfig = {
    key: "sirs",
    version: 1,
    storage,
};

const rootReducer = combineReducers({
    menu: menuSlice,
    contact: contactSlice,
    user: userSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure the store with the persisted reducer
const store = configureStore({
    reducer: persistedReducer,
});

// const store = configureStore({
//     reducer: {
//         menu: menuSlice,
//         contact: contactSlice,
//         user: userSlice,
//     }
// })

// Create a persistor
const persistor = persistStore(store);

export { store, persistor };