import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom';
import './index.css';
import { Provider } from 'react-redux';
import { store, persistor } from './utils/redux/AppSlice';
import { PersistGate } from 'redux-persist/integration/react';
import React, { Suspense, lazy } from 'react';
import NavBar from './components/Navbar';
import Footer from './components/Footer';
import Error from './components/Error';
import AuthRoutes from './components/AuthRoutes.js';
import Routes from './components/Routes.js';
import ContactUs from './components/ContactUs.js';

const Home = lazy(() => import('./components/Home'));
const MandatoryDisclosers = lazy(() => import('./components/MandatoryDisclosers'));
const AboutUs = lazy(() => import('./components/AboutUs'));
const VisionMission = lazy(() => import('./components/VisionMission'));
const PresidentMessage = lazy(() => import('./components/PresidentMessage'));
const ChairmanMessage = lazy(() => import('./components/ChairmanMessage'));
const CaoMessage = lazy(() => import('./components/CaoMessage'));
const PrincipalMessage = lazy(() => import('./components/PrincipalMessage'));
const BoardOfDirectors = lazy(() => import('./components/BoardOfDirectors'));
const Results = lazy(() => import('./components/Results'));
const Staff = lazy(() => import('./components/Staff'));
const Infra = lazy(() => import('./components/Infra'));
const JuniorWing = lazy(() => import('./components/JuniorWing'));
const Gallery = lazy(() => import('./components/Gallery'));
const GalleryTeachers = lazy(() => import('./components/GalleryTeachers'));
const Event = lazy(() => import('./components/Event'));
const SchoolStory = lazy(() => import('./components/SchoolStory'));
const PrimarySchool = lazy(() => import('./components/PrimarySchool'));
const SeniorSchool = lazy(() => import('./components/SeniorSchool'));
const AfterSchoolActivities = lazy(() => import('./components/AfterSchoolActivities'));
const CreativeArts = lazy(() => import('./components/CreativeArts'));
const Sports = lazy(() => import('./components/Sports'));
const Boarding = lazy(() => import('./components/Boarding'));
const BoardingActivities = lazy(() => import('./components/BoardingActivities'));
const AdminLayout = lazy(() => import('./components/AdminLayout'));
const Latest = lazy(() => import('./components/Latest'));

function AppLayout() {
  return (
    <div>
      <NavBar />
      <Suspense fallback={<div>Loading...</div>}>
        <Outlet />
      </Suspense>
      <Footer />
    </div>
  );
}

const appRouter = createBrowserRouter([
  {
    path: "/",
    element: <AppLayout />,
    children: [
      { path: "/", element: <Home /> },
      { path: "/mandatory-disclosers", element: <MandatoryDisclosers /> },
      { path: "/about-us", element: <AboutUs /> },
      { path: "/about-us/vision-mission", element: <VisionMission /> },
      { path: "/about-us/president-message", element: <PresidentMessage /> },
      { path: "/about-us/chairman-message", element: <ChairmanMessage /> },
      { path: "/about-us/cao-message", element: <CaoMessage /> },
      { path: "/about-us/principal-message", element: <PrincipalMessage /> },
      { path: "/about-us/board-of-directors", element: <BoardOfDirectors /> },
      { path: "/academics-and-infra/results", element: <Results /> },
      { path: "/academics-and-infra/staff", element: <Staff /> },
      { path: "/academics-and-infra/infra", element: <Infra /> },
      { path: "/academics-and-infra/junior-wing", element: <JuniorWing /> },
      { path: "/gallery", element: <Gallery /> },
      { path: "/gallery/teachers", element: <GalleryTeachers /> },
      { path: "/gallery/event", element: <Event /> },
      { path: "/school-story", element: <SchoolStory /> },
      { path: "/primary-school", element: <PrimarySchool /> },
      { path: "/senior-school", element: <SeniorSchool /> },
      { path: "/after-school-activities", element: <AfterSchoolActivities /> },
      { path: "/creative-arts", element: <CreativeArts /> },
      { path: "/sports", element: <Sports /> },
      { path: "/boarding", element: <Boarding /> },
      { path: "/boarding-activities", element: <BoardingActivities /> },
      { path: "/latest/:id", element: <Latest /> },
      { path: "/contactus", element: <ContactUs /> },
      ...AuthRoutes,
      {
        path: '/',
        element: <AdminLayout />,
        children: [...Routes],
      },
    ],
    errorElement: <Error />,
  }
]);

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <RouterProvider router={appRouter} />
      </PersistGate>
    </Provider>
  );
}

export default App;
