import AdminDashboard from "./AdminDashboard";
import AdminPopUp from "./AdminPopUp";

const { default: AdminSlider } = require("./AdminSlider");

const Routes = [
    {
        path: "/admin-dashboard",
        element: <AdminDashboard />
    },
    {
        path: "/admin-slider",
        element: <AdminSlider />
    },
    {
        path: "/admin-popup",
        element: <AdminPopUp />
    },
]

export default Routes 