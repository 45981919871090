import React from 'react'
import useGetPopUpImage from '../customhooks/useGetPopUpImage'
import axios from 'axios'
import { BASEURL } from '../utils/Constant'
import { useNavigate } from 'react-router-dom'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';


const AdminPopUp = () => {
    const navigate = useNavigate()
    const { loading, error, popUpImages } = useGetPopUpImage()
    // console.log("popUpImages", popUpImages)

    const initialValues = {
        image: null
    };

    const validationSchema = Yup.object({
        image: Yup.mixed().required('Please select an image')
    });

    const handleSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {
        const formData = new FormData();
        formData.append('image', values.image);

        try {
            const response = await axios.post(BASEURL + 'create-pop-up', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                withCredentials: true
            });

            setStatus({ message: response.data.message });
            navigate("/admin-dashboard");
            // console.log(response.data.data);
            resetForm();
        } catch (error) {
            setStatus({ message: 'Error uploading image' });
            console.error(error);
        } finally {
            setSubmitting(false);
        }
    };


    const deletePopUp = async (deleteId) => {
        // console.log("deleteId", deleteId)
        try {
            const res = await axios.delete(`${BASEURL}delete-popup/${deleteId}`, {
                withCredentials: true
            });
            // console.log("deleted successfully!");
            navigate("/admin-dashboard");
        } catch (error) {
            console.error("error: ", error);
        }
    }

    return (
        <div className='mt-20'>
            <p className='py-3 text-2xl font-bold'>AdminPopUp</p>


            {popUpImages?.length === 0 && (

                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ setFieldValue, isSubmitting, status }) => (
                        <Form className="space-y-6">
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Upload Image</label>
                                <input
                                    type="file"
                                    name="image"
                                    onChange={(event) => {
                                        setFieldValue('image', event.target.files[0]);
                                    }}
                                    className="mt-1 block w-full text-sm text-gray-500
                           file:mr-4 file:py-2 file:px-4
                           file:rounded-full file:border-0
                           file:text-sm file:font-semibold
                           file:bg-blue-50 file:text-blue-700
                           hover:file:bg-blue-100"
                                />
                                <ErrorMessage name="image" component="div" className="text-red-600 text-sm" />
                            </div>

                            <button
                                type="submit"
                                disabled={isSubmitting}
                                className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white
                        ${isSubmitting ? 'bg-blue-300' : 'bg-blue-600 hover:bg-blue-700'}
                        focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`}
                            >
                                {isSubmitting ? 'Uploading...' : 'Upload Image'}
                            </button>
                            {status && status.message && <p className="mt-4 text-green-600">{status.message}</p>}
                        </Form>
                    )}
                </Formik>

            )}



            {popUpImages?.length !== 0 && (
                <div className=" ">
                    <img
                        src={popUpImages[0]?.image}
                        alt="Popup Image"
                        className="w-96  object-contain"
                    />
                </div>
            )}


            {popUpImages?.length !== 0 && (
                <div>
                    <button className='right-0 text-red-800 hover:text-red-400' onClick={() => deletePopUp(popUpImages && popUpImages[0]?._id)}>Delete</button>
                </div>
            )}

        </div>
    )
}

export default AdminPopUp