import React, { useEffect, useState } from 'react';
import { IoMdMenu, IoMdClose } from "react-icons/io";
import { MdOutlineMailOutline } from "react-icons/md";
import { FaArrowRightLong } from "react-icons/fa6";
import { useDispatch, useSelector } from 'react-redux';
import { closeMenue, toggleMenu } from '../utils/redux/slices/menuSlice';
import { toggleContact } from '../utils/redux/slices/contactSlice';
import { Link, useLocation } from "react-router-dom";
import { IoCall } from "react-icons/io5";


const NavBar = () => {

    const location = useLocation();
    const dispatch = useDispatch();
    const isMenue = useSelector((store) => store.menu.isMenueOpen);
    const isContact = useSelector((store) => store.contact.isContactOpen);
    const userToken = localStorage.getItem('token')
    // console.log("token", userToken)

    const [isVisible, setIsVisible] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);

    const toggleMenueTrue = () => {
        dispatch(toggleMenu())
    }

    const toggleContactTrue = () => {
        dispatch(toggleContact())
    }

    const closeMenu = () => {
        dispatch(closeMenue())
    }

    const menuItems = [
        { title: "Our School", link: "/" },
        {
            title: "About Us",
            subItems: [
                { title: "About Us", link: "/about-us" },
                { title: "Vision and Mission", link: "/about-us/vision-mission" },
                { title: "President's Message", link: "/about-us/president-message" },
                { title: "Chairman's Nominee Message", link: "/about-us/chairman-message" },
                { title: "CAO's Message", link: "/about-us/cao-message" },
                { title: "Principal's Message", link: "/about-us/principal-message" },
                { title: "Board of Director's", link: "/about-us/board-of-directors" }
            ]
        },
        { title: "Primary School", link: "/primary-school" },
        { title: "Senior School", link: "/senior-school" },
        { title: "Mandatory Disclosers", link: "/mandatory-disclosers" },

        {
            title: "Outside the Classroom",
            subItems: [
                { title: "After School Activities", link: "/after-school-activities" },
                { title: "Creative Arts", link: "/creative-arts" },
                { title: "Sports at SIRS", link: "/sports" }
            ]
        },
        {
            title: "Boarding",
            subItems: [
                { title: "Boarding at SIRS", link: "/boarding" },
                { title: "Boarding Activities", link: "/boarding-activities" },
            ]
        },

        {
            title: "Academics and Infra",
            subItems: [
                { title: "Results", link: "/academics-and-infra/results" },
                { title: "Staff", link: "/academics-and-infra/staff" },
            ]
        },
        {
            title: "Gallery",
            subItems: [
                { title: "Gallery", link: "/gallery" },
                { title: "Teachers", link: "/gallery/teachers" },
            ]
        },
        { title: "Contact Us", link: "/contactus" },
        ...(userToken ? [{ title: "Admin Dashboard", link: "/admin-dashboard" }] : []),
    ];

    const contactUsLink = [
        { title: "VISIT US", link: "https://maps.app.goo.gl/tAbh31BGbuoGDyP48" },
        { title: "ABOUT US", link: "/about-us" },
        { title: "CONTACT US", link: "/contactus" },
    ]

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY === 0) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);


    return (
        <nav className=" text-white flex justify-between items-center w-full fixed top-0 z-50">

            <div
                className={`${isMenue ? "flex items-center justify-center rounded-br-3xl pb-5 px-4 sm:pb-6 sm:px-6 lg:pb-8 lg:px-8 hover:cursor-pointer transition-all bg-primarycolorlight duration-300 ease-in-out overflow-hidden relative z-40" : "flex items-center justify-center rounded-br-3xl pb-5 px-4 sm:pb-6 sm:px-6 lg:pb-8 lg:px-8 bg-primarycolor hover:bg-opacity-90 hover:cursor-pointer transition-all duration-300 ease-in-out overflow-hidden relative z-70"}`}
                onClick={toggleMenueTrue}
            >
                <div className="flex flex-col justify-center items-center">
                    <span className="text-xs sm:text-sm md:text-base mt-2">{isMenue ? "CLOSE" : "MENU"}</span>
                    {isMenue ? <IoMdClose size={24} /> : <IoMdMenu size={24} />}
                </div>
            </div>

            {isMenue && (
                <div className="absolute top-0 py-32 h-screen w-full sm:w-6/12 md:w-7/12 lg:w-7/12 xl:w-5/12 rounded-r-3xl bg-primarycolor z-10">
                    <div className='w-full md:w-7/12 lg:w-7/12 xl:w-7/12 px-5'>
                        <ul className='flex flex-col'>
                            {menuItems.map((item, index) => (
                                <div key={index} className="relative group">
                                    <Link
                                        to={item.link || "#"}
                                        className={`text-xl px-4 py-3 font-normal border-b hover:bg-primarycolorlight cursor-pointer flex justify-between items-center ${location.pathname === item.link && location.pathname === item.link ? 'bg-primarycolorlight text-white font-bold' : ''}`}
                                        onClick={() => {
                                            closeMenu();
                                            window.scrollTo({ top: 0, behavior: "smooth" });
                                        }}
                                    >
                                        {item.title}
                                        {item.subItems && <FaArrowRightLong />}
                                    </Link>
                                    {item.subItems && (
                                        <div className="absolute hidden group-hover:block bg-primarycolor shadow-md rounded-md min-w-[200px] z-20 left-0 w-full top-full md:left-full md:top-0 md:w-60 border-gray-400 border-[0.1px]">
                                            {item.subItems.map((subItem, subIndex) => (
                                                <Link
                                                    key={subIndex}
                                                    to={subItem.link}
                                                    className={`block text-[18px] px-4 py-3 rounded-md hover:bg-primarycolorlight cursor-pointer border-[0.1px] ${location.pathname === subItem.link ? 'bg-primarycolorlight text-white font-bold' : ''}`}
                                                    onClick={() => {
                                                        closeMenu();
                                                        window.scrollTo({ top: 0, behavior: "smooth" });
                                                    }}

                                                >
                                                    {subItem.title}
                                                </Link>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            ))}
                        </ul>
                    </div>
                </div>
            )}

            {/* {!isMenue && (
                <div className="flex flex-grow justify-center items-center px-4 sm:px-6 md:px-8 lg:px-10 xl:px-12">
                    <div className=''>
                        <a href="/" className="flex items-center">
                            <img src="https://img.freepik.com/free-vector/vector-damask-seamless-pattern-background-classical-luxury-old-fashioned-damask-ornament-royal-victorian-seamless-texture-wallpapers-textile-wrapping-exquisite-floral-baroque-template_1217-738.jpg?t=st=1738302869~exp=1738306469~hmac=b040fa14fb0ac24fb399babda60c35cb673a871511f8187ab6723487fc61b266&w=1380" alt="School Logo" className="w-16 md:w-20 sm:w-16 object-contain" />
                            <span className="hidden sm:block text-xl sm:text-xl md:text-md lg:text-md xl:text-xl font-bold text-yellow-500 sm:ml-3 text-center">
                                Sanganabasava International Residential School
                            </span>
                        </a>
                    </div>
                </div>
            )} */}

            <div className={`transition-all duration-300 ${isVisible ? "opacity-100" : "opacity-0 pointer-events-none"}`}>
                {!isMenue && (
                    <div className="flex flex-grow justify-center items-center px-4 sm:px-6 md:px-8 lg:px-10 xl:px-12">
                        <div>
                            <a href="/" className="flex items-center">
                                <img
                                    src="/sirs-logo.png"
                                    alt="School Logo"
                                    className="w-auto h-auto max-w-[85px] sm:max-w-[80px] md:max-w-[110px] lg:max-w-[120px] xl:max-w-[110px] object-contain"
                                />
                                <span className="hidden sm:block text-xl sm:text-xl md:text-md lg:text-md xl:text-xl font-bold text-white sm:ml-3  px-3 py-3 bg-opacity-35 text-center">
                                    Sanganabasava International
                                    <p> Residential School</p>
                                </span>
                            </a>
                        </div>
                    </div>
                )}
            </div>

            <div
                className={`${isContact ? "flex items-center justify-center rounded-bl-3xl pb-5 px-4 sm:pb-6 sm:px-6 lg:pb-8 lg:px-8 hover:cursor-pointer transition-all bg-secondarycolorlight duration-300 ease-in-out overflow-hidden relative z-50" : "flex items-center justify-center rounded-bl-3xl pb-5 px-4 sm:pb-6 sm:px-6 lg:pb-8 lg:px-8 bg-primarycolor hover:bg-opacity-90 hover:cursor-pointer transition-all duration-300 ease-in-out overflow-hidden relative z-50"}`}
                onClick={toggleContactTrue}
            >
                <div className="flex flex-col justify-center items-center">
                    <span className="text-xs sm:text-sm md:text-base mt-2 sm:mt-3">{isContact ? "CLOSE" : "Contact"}</span>
                    {isContact ? (
                        <IoMdClose size={20} sm={22} md={24} lg={26} xl={28} />
                    ) : (
                        <MdOutlineMailOutline size={20} sm={22} md={24} lg={26} xl={28} />
                    )}
                </div>

            </div>

            {isContact && (
                <>
                    <div className="absolute top-0 right-0 w-full h-auto bg-[#ECECEC] z-10 flex flex-col md:flex-row justify-evenly items-center  px-4 sm:px-10 lg:px-44 rounded-r-3xl">
                        <div className="absolute top-0 right-0 w-full h-auto bg-[#ECECEC] z-10 flex flex-col md:flex-row justify-evenly  items-start md:py-16 px-4 sm:px-10 lg:px-44 rounded-r-3xl">

                            <div className="w-full py-5 sm:w-6/12 md:w-3/12 flex flex-col items-center gap-2">
                                {contactUsLink.map((data, index) => (
                                    <Link
                                        to={`${data.link}`}
                                        onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
                                        className="bg-primarycolor shadow-lg sm:shadow-xl md:shadow-2xl lg:shadow-[0px_10px_30px_rgba(0,0,0,0.3)] p-2 rounded-md w-32 text-center transition-all duration-300 hover:shadow-[0px_15px_40px_rgba(0,0,0,0.4)]"
                                        key={index}
                                    >
                                        {data.title}
                                    </Link>
                                ))}

                                <p className='md:hidden font-bold py-3 border-b-2 border-yellow-700 flex justify-center items-center text-primarycolor text-md'><IoCall className='mr-2' /> Tel <span className='font-normal'>+91 8787676768</span> </p>
                            </div>

                            {/* Center Section - Address */}
                            <div className=" hidden w-full sm:w-8/12 md:w-4/12 md:flex flex-col items-center gap-1 border-r-0 md:border-r-2 border-l-0 md:border-l-2 border-yellow-700 text-center md:text-left">
                                <p className="text-primarycolor font-bold text-xl px-3 text-center">Sanganabasava International Residential School</p>
                                <p className="text-md text-primarycolor">Residential School, Kavalgi Post</p>
                                <p className="text-md text-primarycolor">Honnutagi, Vijayapura,</p>
                                <p className="text-md text-primarycolor"> Karnataka, India - 586101</p>

                                <p className="text-md text-primarycolor font-semibold">
                                    <a href="mailto:contact@sirs.ac.in ">contact@sirs.ac.in </a>
                                </p>
                                <p className="text-md text-primarycolor font-semibold">+91 73386 28376</p>

                            </div>

                            {/* Right Section - Key Links */}
                            <div className="hidden w-full sm:w-6/12 md:w-3/12 md:flex flex-col items-center gap-2 mt-4 md:mt-0">
                                <p className="text-primarycolor font-extrabold text-lg">Key Links</p>
                                <Link to={'/'} className="text-primarycolor">OUR SCHOOL</Link>
                                <Link to={'https://maps.app.goo.gl/tAbh31BGbuoGDyP48'} className="text-primarycolor">VISIT US</Link>
                                <Link to={'/about-us'} className="text-primarycolor">ABOUT US</Link>

                            </div>
                            <div className=" hidden md:block w-[200px] justify-center border-l-0 md:border-l-2 border-yellow-700 px-3 ps-5">
                                <p className="text-primarycolor font-extrabold text-lg mb-3">Social Media</p>
                                <img
                                    src="/Instagram.jpg"
                                    className="w-full max-w-[120px] h-auto aspect-square object-cover"
                                    alt="instagram scanner"
                                />
                            </div>
                        </div>

                    </div>
                    {/* <div className='absolute left-[12%] lg:left-[15%] md:left-[6%]  rounded-full z-0 mt-[99%] sm:mt-[80%] md:mt-[90%] lg:mt-[70%] xl:left-[26%] xl:mt-[56%]'>
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3847.1779855765935!2d75.11881277478287!3d15.366844085215424!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bb8d7376cee471f%3A0xd1628aae8d84451a!2sWedding%20Cluster!5e0!3m2!1sen!2sin!4v1697187521924!5m2!1sen!2sin"
                            className=" w-full  rounded-lg md:w-[700px] border-0"
                            style={{ filter: "opacity(0.8)" }}
                            allowFullScreen
                            loading="lazy"
                        ></iframe>
                    </div> */}
                </>
            )}
        </nav >
    );
};

export default NavBar;





