import React from 'react'
import Login from './Login';
import { Navigate } from 'react-router-dom';
import Register from './Register';

const isAuthenticated = () => {
    return !!localStorage.getItem("token");
};

const AuthRoutes = [
    {
        path: "/login",
        element: isAuthenticated() ? <Navigate to="/" /> : <Login />,
    },
    {
        path: "/sirsregister",
        element: isAuthenticated() ? <Navigate to="/" /> : <Register />
    },

]

export default AuthRoutes