import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { BASEURL } from '../utils/Constant';
import { useNavigate } from 'react-router-dom';
import useGetAllSlider from '../customhooks/useGetAllSlider';

const AdminSlider = () => {

    const navigate = useNavigate()
    const { sliderImage } = useGetAllSlider()
    const sliderImages = sliderImage && sliderImage[0]?.images
    // console.log("sliderImages", sliderImage[0]?._id)

    const initialValues = {
        images: []
    };

    const validationSchema = Yup.object({
        images: Yup.array().min(1, 'Please select at least one image').required('Please select images')
    });

    const handleSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {
        // console.log(values)
        const formData = new FormData();
        values.images.forEach((image) => formData.append('images', image));
        const token = localStorage.getItem("token")
        // console.log("token--", token)
        try {
            const response = await axios.post(BASEURL + 'newslider', formData, {

                withCredentials: true
            });
            setStatus({ message: response.data.message });
            // console.log(response.data.data);
            navigate("/admin-dashboard")
            resetForm();
        } catch (error) {
            setStatus({ message: 'Error creating slider' });
            console.error(error);
        } finally {
            setSubmitting(false);
        }
    };


    const deleteSlider = async (delete_Id) => {
        // console.log("clicked to delete");
        try {
            const res = await axios.delete(`${BASEURL}deleteslider/${delete_Id}`, {
                withCredentials: true
            });
            // console.log("deleted successfully!");
            navigate("/admin-dashboard");
        } catch (error) {
            console.error("error: ", error);
        }
    };

    return (
        <div className='mt-20'>
            {!sliderImages &&
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ setFieldValue, isSubmitting, status }) => (
                        <Form className="space-y-6">
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Upload Images</label>
                                <input
                                    type="file"
                                    name="images"
                                    multiple
                                    onChange={(event) => {
                                        setFieldValue('images', Array.from(event.target.files));
                                    }}
                                    className="mt-1 block w-full text-sm text-gray-500
                                       file:mr-4 file:py-2 file:px-4
                                       file:rounded-full file:border-0
                                       file:text-sm file:font-semibold
                                       file:bg-blue-50 file:text-blue-700
                                       hover:file:bg-blue-100"
                                />
                                <ErrorMessage name="images" component="div" className="text-red-600 text-sm" />
                            </div>

                            <button
                                type="submit"
                                disabled={isSubmitting}
                                className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white
                                    ${isSubmitting ? 'bg-blue-300' : 'bg-blue-600 hover:bg-blue-700'}
                                    focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`}
                            >
                                {isSubmitting ? 'Creating Slider...' : 'Create Slider'}
                            </button>
                            {status && status.message && <p className="mt-4 text-green-600">{status.message}</p>}
                        </Form>
                    )}
                </Formik>}

            <p className='m-0 py-3 text-2xl font-bold '>Main slider</p>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 p-4">
                {sliderImages?.map((data, index) => (
                    <img
                        key={index}
                        src={data}
                        alt={`Hero Slide ${index + 1}`}
                        className="w-full h-auto rounded-lg shadow-md"
                    />
                ))}
            </div>
            {sliderImages && (
                <div>
                    <button className='right-0 text-red-800 hover:text-red-400' onClick={() => deleteSlider(sliderImage && sliderImage[0]?._id)}>Delete</button>
                </div>
            )}

        </div>



    )
}

export default AdminSlider